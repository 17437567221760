import React from "react";

const UnderlineIcon = () => (
    <div>
        <svg id="Effect_over" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24">
            <path className="cls-1"
                  d="m37.1186523,22.2243297H2.8813477c-.5527344,0-1-.4472656-1-1s.4472656-1,1-1h34.2373047c.5527344,0,1,.4472656,1,1s-.4472656,1-1,1Z"/>
        </svg>
        <svg id="Abc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24">
            <path className="cls-1"
                  d="m12.2472534,7.0216954h-1l-3.4146729,9.9360352-1.3421631.2011719v.6523438h3.6679688v-.6523438l-1.5610352-.2146606.8873291-2.6124878h3.7154541l.883606,2.640564-1.5728149.1885376v.6503906h4.3554688v-.6503906l-1.2130737-.1465454-3.4060669-9.9926147Zm-2.5084839,6.5620117l1.6174927-4.7624512,1.5935669,4.7624512h-3.2110596Z"/>
            <path className="cls-1"
                  d="m22.4992065,10.0055822c-.8676147,0-1.6935425.3883057-2.4570312,1.2390137v-2.8654785l.0644531-2.2705078-.2265625-.1298828-2.4521484.7202148v.5664062l1.3095703.1582031v7.0273438c0,.713501-.0081787,1.7623901-.0250854,2.5655518l-1.2024536.2244873v.5712891l2.40625.1630859.1329956-1.0887451c.6882324.8653564,1.4415283,1.1346436,2.2078247,1.1346436,1.8681641,0,3.2890625-1.6152344,3.2890625-4.0869141,0-2.4765625-1.3476562-3.9287109-3.046875-3.9287109Zm-.5058594,7.1083984c-.5965576,0-1.2493896-.270813-1.9033203-.9000854v-4.3561401c.8547974-.7310791,1.4859009-.9429932,1.9804688-.9429932,1.2011719,0,2.0380859,1.1025391,2.0380859,3.1582031,0,2.0546875-.9746094,3.0410156-2.1152344,3.0410156Z"/>
            <path className="cls-1"
                  d="m33.1154175,15.7555822c-.4941406.7851562-1.2958984,1.2275391-2.2158203,1.2275391-1.4951172,0-2.5566406-1.0986328-2.5566406-3.1064453,0-2.0546875.9921875-3.1445312,2.3574219-3.1445312.2631226,0,.5565186.0438843.8613892.1315918l.2948608,1.0617676c.109375.4423828.3359375.765625.8691406.765625.4101562,0,.65625-.1953125.7412109-.6054688-.203125-1.1894531-1.3876953-2.0800781-2.8271484-2.0800781-1.9326172,0-3.703125,1.5048828-3.703125,4.0244141,0,2.4628906,1.4003906,3.9912109,3.59375,3.9912109,1.5048828,0,2.5371094-.7294922,2.9785156-2.0566406l-.3935547-.2089844Z"/>
        </svg>
    </div>
)

export default UnderlineIcon;

