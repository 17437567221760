import React from "react";


const TinyTextIcon = () => (
    <svg id="Abc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24">
        <path className="cls-1"
              d="m12.5390733,17.6000682l3.5356445-10.4960938h1.5039062l3.5361328,10.4960938h-1.4077148l-.9921875-3.2001953h-3.8081055l-1.0078125,3.2001953h-1.3598633Zm3.199707-5.8720703l-.4960938,1.5996094h3.1362305l-.4960938-1.5996094c-.1816406-.5869141-.3598633-1.1708984-.5361328-1.7519531-.1757812-.5810547-.34375-1.1757812-.5039062-1.7841797h-.0639648c-.1708984.6083984-.3413086,1.203125-.5117188,1.7841797-.1708984.5810547-.347168,1.1650391-.5283203,1.7519531Z"/>
        <polygon className="cls-1"
                 points="27.4609267 5.6547852 27.4609267 4.6547852 22.417958 4.6547852 22.417958 5.6547852 24.4394424 5.6547852 24.4394424 18.3452148 22.417958 18.3452148 22.417958 19.3452148 27.4609267 19.3452148 27.4609267 18.3452148 25.4394424 18.3452148 25.4394424 5.6547852 27.4609267 5.6547852"/>
    </svg>
)

export default TinyTextIcon;