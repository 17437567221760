import React from "react";


const HighlightIcon = () => (
    <svg id="Abc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24">
        <path className="cls-1"
              d="m22.0708008,10.9147949c-.4945679,0-1.1256714.2119141-1.9804688.9429932v4.3561401c.6539307.6292725,1.3067627.9000854,1.9033203.9000854,1.140625,0,2.1152344-.9863281,2.1152344-3.0410156,0-2.0556641-.8369141-3.1582031-2.0380859-3.1582031Z"/>
        <polygon className="cls-1"
                 points="9.7390747 13.5837402 12.9501343 13.5837402 11.3565674 8.8212891 9.7390747 13.5837402"/>
        <path className="cls-1"
              d="m1.8812256,1.7756348v20.4486694h36.2375488V1.7756348H1.8812256Zm14.9854736,16.0356445h-4.3554688v-.6503906l1.5728149-.1885376-.883606-2.640564h-3.7154541l-.8873291,2.6124878,1.5610352.2146606v.6523438h-3.6679688v-.6523438l1.3421631-.2011719,3.4146729-9.9360352h1l3.4060669,9.9926147,1.2130737.1465454v.6503906Zm5.390625.2099609c-.7662964,0-1.5195923-.2692871-2.2078247-1.1346436l-.1329956,1.0887451-2.40625-.1630859v-.5712891l1.2024536-.2244873c.0169067-.8031616.0250854-1.8520508.0250854-2.5655518v-7.0273438l-1.3095703-.1582031v-.5664062l2.4521484-.7202148.2265625.1298828-.0644531,2.2705078v2.8654785c.7634888-.850708,1.5894165-1.2390137,2.4570312-1.2390137,1.6992188,0,3.046875,1.4521484,3.046875,3.9287109,0,2.4716797-1.4208984,4.0869141-3.2890625,4.0869141Zm8.2734375,0c-2.1933594,0-3.59375-1.5283203-3.59375-3.9912109,0-2.5195312,1.7705078-4.0244141,3.703125-4.0244141,1.4394531,0,2.6240234.890625,2.8271484,2.0800781-.0849609.4101562-.3310547.6054688-.7412109.6054688-.5332031,0-.7597656-.3232422-.8691406-.765625l-.2948608-1.0617676c-.3048706-.0877075-.5982666-.1315918-.8613892-.1315918-1.3652344,0-2.3574219,1.0898438-2.3574219,3.1445312,0,2.0078125,1.0615234,3.1064453,2.5566406,3.1064453.9199219,0,1.7216797-.4423828,2.2158203-1.2275391l.3935547.2089844c-.4414062,1.3271484-1.4736328,2.0566406-2.9785156,2.0566406Z"/>
    </svg>
)

export default HighlightIcon;