import React from "react";

const SquigglyIcon = () => (
    <div>
        <svg id="Effect_over" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24">
            <path className="cls-1"
                  d="m37.1189519,11.3418961c-2.0625,0-3.1630859.8808594-4.0478516,1.5888672-.8027344.6425781-1.4365234,1.1503906-2.796875,1.1503906-1.359375,0-1.9931641-.5078125-2.7958984-1.1503906-.8837891-.7080078-1.984375-1.5888672-4.0458984-1.5888672-2.0625,0-3.1640625.8808594-4.0488281,1.5888672-.8027344.6425781-1.4375,1.1503906-2.7988281,1.1503906-1.3623047,0-1.9970703-.5078125-2.8017578-1.1503906-.8847656-.7080078-1.9863281-1.5888672-4.0498047-1.5888672s-3.1650391.8808594-4.0498047,1.5888672c-.8046875.6425781-1.4394531,1.1503906-2.8017578,1.1503906-.5527344,0-1,.4472656-1,1s.4472656,1,1,1c2.0634766,0,3.1650391-.8808594,4.0498047-1.5888672.8046875-.6425781,1.4394531-1.1503906,2.8017578-1.1503906s1.9970703.5078125,2.8017578,1.1503906c.8847656.7080078,1.9863281,1.5888672,4.0498047,1.5888672,2.0625,0,3.1640625-.8808594,4.0488281-1.5888672.8027344-.6425781,1.4375-1.1503906,2.7988281-1.1503906,1.359375,0,1.9931641.5078125,2.7958984,1.1503906.8837891.7080078,1.984375,1.5888672,4.0458984,1.5888672s3.1621094-.8808594,4.046875-1.5888672c.8027344-.6425781,1.4375-1.1503906,2.7978516-1.1503906.5527344,0,1-.4472656,1-1s-.4472656-1-1-1Z"/>
        </svg>
        <svg id="Abc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24">
            <path className="cls-1"
                  d="m23.4324285,10.3418961c.144104,0,.2719116.0149536.4082642.0221558-.4049072-.2272339-.8555908-.3584595-1.3411865-.3584595-.8591309,0-1.6775513.3797607-2.4348755,1.2131348.8218994-.4812622,1.8844604-.8768311,3.3677979-.8768311Z"/>
            <path className="cls-1"
                  d="m10.8115178,10.4261246l.5450439-1.6048584.6294556,1.8810425c.7678833.2662964,1.362854.644104,1.8621826,1.0153198l-1.600647-4.6959229h-1l-1.1478882,3.3400879c.2485352.0117188.4873047.0315552.711853.0643311Z"/>
            <path className="cls-1"
                  d="m18.7377874,12.166359c.0076294-.0061035.0133667-.0101929.0210571-.0163574.3626099-.2902222.7774658-.6206665,1.2836304-.9194336v-2.8514404l.0644531-2.2705078-.2265625-.1298828-2.4521484.7202148v.5664062l1.3095703.1582031v4.7427979Z"/>
            <path className="cls-1"
                  d="m28.1043035,12.1509171c.1564941.1252441.2932739.2315674.428894.3327637.3493042-1.1487427,1.1546021-1.7515259,2.1674805-1.7515259.2631226,0,.5565186.0438843.8613892.1315918l.2948608,1.0617676c.0487671.1972656.1270142.3644409.2391357.4962158.112915-.0863647.2225342-.1695557.3504639-.2719727.2480469-.1984863.5219116-.4156494.8309326-.6289062-.4255981-.8934326-1.4386597-1.5152588-2.6373291-1.5152588-1.2355347,0-2.3989258.6206665-3.081665,1.7226562.1937256.1446533.3779907.288208.5458374.4226685Z"/>
            <path className="cls-1"
                  d="m24.0886785,14.4026261c-.1026001,1.8222046-1.0196533,2.7113647-2.0950317,2.7113647-.5965576,0-1.2493896-.270813-1.9033203-.9000854v-.093689c-.3926392.2423096-.8449707.4636841-1.3725586.6351318-.0015869.0899048-.0032959.1760864-.0050659.2611084l-1.2024536.2244873v.5712891l2.40625.1630859.1329956-1.0887451c.6882324.8653564,1.4415283,1.1346436,2.2078247,1.1346436,1.5455322,0,2.7711792-1.1165161,3.1531982-2.8979492-.4567261-.3609619-.815979-.6149292-1.3218384-.7206421Z"/>
            <path className="cls-1"
                  d="m30.2742253,17.0811539c-1.0847778,0-1.9368896-.2163086-2.6424561-.5181274.6190186.9244995,1.6178589,1.4581909,2.8989868,1.4581909,1.3455811,0,2.3097534-.5869751,2.8150635-1.6591187-.7792969.4056396-1.7575684.7190552-3.0715942.7190552Z"/>
            <path className="cls-1"
                  d="m13.9481145,16.5662003l.1359253.4061279-1.5728149.1885376v.6503906h4.3554688v-.6503906l-.9675293-.1168823c-.7648926-.0681763-1.4039917-.244873-1.9510498-.4777832Z"/>
            <path className="cls-1"
                  d="m9.4733831,14.3658829c-.3131104.0223389-.5800171.0791016-.8106079.177002l-.829895,2.414856-1.3421631.2011719v.6523438h3.6679688v-.6523438l-1.5610352-.2146606.8757324-2.5783691Z"/>
        </svg>
    </div>
)

export default SquigglyIcon;