import React from "react";


const ParagraphSidebarIcon = () => (
    <div>
        <svg id="Abc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24">
            <rect className="cls-1" x="6.8442383" y="1.7756525" width="4" height="20.4486949"/>
        </svg>
        <svg id="Abc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24">
            <path className="cls-1"
                  d="m16.6923828,9.71875l.9736328-.1435547h.1816406l.9814453.1435547v.4511719h-2.1367188v-.4511719Zm2.7929688-5.9462891h.7871094l2.1835938,6.3974609h-1.2734375l-1.78125-5.4462891.2910156-.2460938-1.8945312,5.6923828h-.5107422l2.1982422-6.3974609Zm-1.0771484,3.9316406h2.5917969l.1845703.5009766h-2.9335938l.1572266-.5009766Zm1.75,2.0253906l1.1455078-.1435547h.4208984l1.2119141.1435547v.4404297h-2.7783203v-.4404297Z"/>
            <path className="cls-1"
                  d="m23.3300781,9.78125l.9658203-.1923828-.3583984.5849609c.0185547-.4326172.0283203-1.4208984.0283203-2.015625v-4.1347656l-.6943359-.0859375v-.3710938l1.7148438-.4316406.1445312.0869141-.0380859,1.3525391v1.6494141l.0283203.1240234v3.0957031l-.1699219.84375-1.6210938-.1132812v-.3925781Zm3.8222656-1.8964844c0-1.1855469-.4453125-1.7626953-1.09375-1.7626953-.4267578,0-.8759766.34375-1.3789062.8544922l-.0869141-.1660156c.5537109-.9394531,1.1845703-1.3710938,1.9355469-1.3710938,1.03125,0,1.8515625.8525391,1.8515625,2.390625,0,1.5126953-.9082031,2.4755859-2.0097656,2.4755859-.7265625,0-1.2753906-.3759766-1.7421875-1.3701172l.0869141-.1679688c.4462891.5810547.8515625.8652344,1.3134766.8652344.6240234,0,1.1240234-.5634766,1.1240234-1.7480469Z"/>
            <path className="cls-1"
                  d="m30.2890625,7.7519531c0,1.15625.5869141,1.7900391,1.4404297,1.7900391.5068359,0,.9228516-.2402344,1.2089844-.6396484l.2832031.2070312c-.3154297.7900391-.9677734,1.1962891-1.8740234,1.1962891-1.3115234,0-2.2666016-.8798828-2.2666016-2.421875,0-1.5390625,1.1083984-2.4443359,2.3984375-2.4443359.9355469,0,1.6455078.5810547,1.7421875,1.3193359-.0761719.2929688-.2490234.4287109-.5410156.4287109-.3486328,0-.5683594-.2128906-.625-.6748047l-.1630859-.7636719.5722656.3759766c-.3125-.1542969-.5996094-.2216797-.8857422-.2216797-.6914062,0-1.2900391.5849609-1.2900391,1.8486328Z"/>
            <path className="cls-1"
                  d="m18.7128906,18.1337891c.3994141-.1298828,1.0429688-.3056641,1.5087891-.4033203v.3740234c-.4423828.1005859-.984375.2617188-1.2685547.3837891-.6455078.2587891-.8369141.6025391-.8369141.96875,0,.4941406.2939453.7060547.6757812.7060547.2578125,0,.4287109-.0996094.8818359-.4257812l.2910156-.2177734.1171875.1699219-.3417969.359375c-.5195312.5332031-.8320312.8164062-1.4736328.8164062-.7216797,0-1.2724609-.4208984-1.2724609-1.2138672,0-.6113281.3339844-1.0683594,1.71875-1.5175781Zm.9130859,1.7617188v-2.296875c0-.8955078-.2158203-1.1357422-.8388672-1.1357422-.2275391,0-.4941406.0283203-.8808594.1552734l.5244141-.3369141-.1582031.7441406c-.0546875.4619141-.2832031.6679688-.5986328.6679688-.3007812,0-.4931641-.1542969-.5410156-.4326172.1152344-.7646484.8574219-1.2617188,2.0322266-1.2617188,1.1123047,0,1.5957031.4863281,1.5957031,1.734375v2.0947266c0,.3085938.1054688.4306641.2841797.4306641.1318359,0,.2226562-.0693359.3320312-.2119141l.2236328.1904297c-.2304688.4453125-.53125.609375-.9882812.609375-.5908203,0-.9433594-.3632812-.9863281-.9511719Z"/>
            <path className="cls-1"
                  d="m21.8994141,20.3408203l.9658203-.1923828-.3583984.5849609c.0185547-.4326172.0283203-1.4208984.0283203-2.015625v-4.1347656l-.6943359-.0859375v-.3710938l1.7148438-.4316406.1445312.0869141-.0380859,1.3525391v1.6494141l.0283203.1240234v3.0957031l-.1699219.84375-1.6210938-.1132812v-.3925781Zm3.8222656-1.8964844c0-1.1855469-.4453125-1.7626953-1.09375-1.7626953-.4267578,0-.8759766.34375-1.3789062.8544922l-.0869141-.1660156c.5537109-.9394531,1.1845703-1.3710938,1.9355469-1.3710938,1.03125,0,1.8515625.8525391,1.8515625,2.390625,0,1.5126953-.9082031,2.4755859-2.0097656,2.4755859-.7265625,0-1.2753906-.3759766-1.7421875-1.3701172l.0869141-.1679688c.4462891.5810547.8515625.8652344,1.3134766.8652344.6240234,0,1.1240234-.5634766,1.1240234-1.7480469Z"/>
            <path className="cls-1"
                  d="m28.8583984,18.3115234c0,1.15625.5869141,1.7900391,1.4404297,1.7900391.5068359,0,.9228516-.2402344,1.2089844-.6396484l.2832031.2070312c-.3154297.7900391-.9677734,1.1962891-1.8740234,1.1962891-1.3115234,0-2.2666016-.8798828-2.2666016-2.421875,0-1.5390625,1.1083984-2.4443359,2.3984375-2.4443359.9355469,0,1.6455078.5810547,1.7421875,1.3193359-.0761719.2929688-.2490234.4287109-.5410156.4287109-.3486328,0-.5683594-.2128906-.625-.6748047l-.1630859-.7636719.5722656.3759766c-.3125-.1542969-.5996094-.2216797-.8857422-.2216797-.6914062,0-1.2900391.5839844-1.2900391,1.8486328Z"/>
        </svg>
    </div>
)
export default ParagraphSidebarIcon;